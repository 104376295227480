import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    position: 'fixed',
    color: MAIN_COLORS.UI_LIGHT,
    top: 12,
    left: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 99,
  },
  header: {
    width: '100%',
    height: 'auto',
    paddingBottom: 48,
    backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.up('sm')]: {
      height: 'auto',
      paddingBottom: 48,
      paddingLeft: 56,
      paddingRight: 56,
    },
    [breakpoints.up('md')]: {
      height: 632,
    },
  },
  headerContent: {
    maxWidth: 1280 - 56 - 56,
    paddingTop: 84,
    [breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: `1fr 1fr`,
      gridTemplateRows: `auto`,
      height: 632,
      textAlign: 'left',
      columnGap: 32,
      paddingTop: 132,
    },
    [breakpoints.only('sm')]: {
      paddingTop: 104,
      textAlign: 'center',
    },
    [breakpoints.only('xs')]: {
      paddingTop: 84,
    },
  },
  headerImgContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerImg: {
    position: 'relative',
    textAlign: 'center',
    width: 486,
    height: 400,
    marginRight: 64,
    [breakpoints.down('md')]: {
      marginRight: 0,
    },
    [breakpoints.down('sm')]: {
      marginRight: 0,
      width: 316,
      height: 260,
    },
    [breakpoints.down('xs')]: {
      width: 316,
      height: 260,
    },
  },
  headerText: {
    color: MAIN_COLORS.UI_LIGHT,
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.only('sm')]: {
      alignItems: 'center',
    },
  },
  buttons: {
    display: 'flex',
    paddingTop: 32,
    flexDirection: 'row',
    [breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonLogin: {
    paddingLeft: 24,
    [breakpoints.only('sm')]: {
      paddingLeft: 0,
      paddingTop: 24,
    },
  },
  howItWorks: {
    maxWidth: 1280,
    display: 'grid',
    gridTemplateAreas: `'one one' 
                        'two three'
                        'four five'
                        'six seven'`,
    padding: '64px 56px 80px',
    gap: '64px 32px',
    [breakpoints.only('sm')]: {
      gap: '41px 32px',
    },
    [breakpoints.only('xs')]: {
      gridTemplateAreas: `'one'
                          'two'
                          'three'
                          'four'
                          'five'
                          'six'
                          'seven'`,
      padding: '64px 16px',
      gap: 0,
    },
  },
  howItWorksTitle: {
    gridArea: 'one',
    paddingBottom: 40,
    [breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
  howItWorksText: {
    paddingBottom: 40,
    [breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
  howItWorksImg: {
    position: 'relative',
    width: 288,
    height: 251,
    paddingBottom: 16,
    [breakpoints.up('md')]: {
      marginLeft: (440 - 288) / 2,
      marginRight: (440 - 288) / 2,
    },
    [breakpoints.up('lg')]: {
      marginLeft: (568 - 288) / 2,
      marginRight: (568 - 288) / 2,
    },
  },

  howItWorksText1: {
    gridArea: 'three',
    [breakpoints.up('sm')]: {
      gridArea: 'two',
    },
  },
  howItWorksImg1: {
    gridArea: 'two',
    [breakpoints.up('sm')]: {
      gridArea: 'three',
    },
  },
  howItWorksText2: {
    gridArea: 'five',
    [breakpoints.up('sm')]: {
      gridArea: 'four',
    },
  },
  howItWorksImg2: {
    gridArea: 'four',
    [breakpoints.up('sm')]: {
      gridArea: 'five',
    },
  },
  howItWorksText3: {
    gridArea: 'seven',
    [breakpoints.up('sm')]: {
      gridArea: 'six',
    },
  },
  howItWorksImg3: {
    gridArea: 'six',
    [breakpoints.up('sm')]: {
      gridArea: 'seven',
    },
  },

  howToStart: {
    maxWidth: 1280,
    display: 'grid',
    gridTemplateAreas: `'one'
                        'two'
                        'three'
                        'four'
                        'five'`,
    padding: '64px 16px',
    gap: '40px',
    [breakpoints.up('sm')]: {
      padding: '64px 56px',
      gap: '64px 32px',
      gridTemplateAreas: `'one one' 
                          'two three'
                          'four five'`,
    },
    [breakpoints.up('md')]: {
      gridTemplateAreas: `'one one one one' 
                          'two three four five'`,
    },
  },
  howToStartTitle: {
    gridArea: 'one',
  },
  howToStartImg: {
    position: 'relative',
    width: 288,
    height: 251,
    marginBottom: 16,
    [breakpoints.up('sm')]: {
      width: 229,
      height: 199,
    },
    [breakpoints.up('md')]: {
      width: 204,
      height: 178,
    },
    [breakpoints.up('lg')]: {
      width: 268,
      height: 233,
    },
  },
  howToStartBlock1: {
    gridArea: 'two',
  },
  howToStartBlock2: {
    gridArea: 'three',
  },
  howToStartBlock3: {
    gridArea: 'four',
  },
  howToStartBlock4: {
    gridArea: 'five',
  },
  dataAndSecurity: {
    width: '100%',
    maxWidth: 1280,
    display: 'grid',
    padding: '64px 16px',
    [breakpoints.up('sm')]: {
      padding: '64px 56px',
    },
    [breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
    },
  },
  border: {
    width: 'calc(100% - 112px)',
    borderTop: `1px solid ${MAIN_COLORS.UI_BORDER}`,
    maxWidth: 1280,
    [breakpoints.only('xs')]: {
      width: 'calc(100% - 32px)',
    },
  },
  tryMotoom: {
    backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
    color: MAIN_COLORS.UI_LIGHT,
    padding: '64px 56px 80px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  footer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gridTemplateRows: 'auto',
    padding: '64px 56px 80px',
    gap: '64px 32px',
    color: MAIN_COLORS.UI_LIGHT,
    backgroundColor: MAIN_COLORS.BACKGROUND_PERSISTENT_NOTIFICATION,
    [breakpoints.only('xs')]: {
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      textAlign: 'center',
      gap: '32px',
    },
  },
  footerLink: {
    whiteSpace: 'nowrap',
  },
  footerCopy: {
    textAlign: 'end',
  },
}));
