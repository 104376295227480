import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Landing } from 'components';

import { getInitialUser } from 'utils/api';
import { GA_TRACKING_ID } from 'utils/gtag';
import { LAYOUTS } from 'utils/paths';
import { PUBLIC_URL } from 'utils/constants';

interface Props {
  user: User;
}

const Home: NextPage<Props> = ({ user }) => {
  const metaKeys = [
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-title', content: 'motoom.app' },
    { name: 'theme-color', content: '#FFFFFF' },
    {
      name: 'name',
      content: 'Motoom',
    },
    {
      name: 'description',
      content:
        'Track tremor degree remotely with the online spiral drawing test',
    },
    {
      property: 'og:site_name',
      content: 'Motoom',
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: 'Motoom' },
    {
      property: 'og:description',
      content:
        'Track tremor degree remotely with the online spiral drawing test',
    },
    { property: 'og:url', content: 'https://motoom.app' },
    { name: 'twitter:title', content: 'Motoom' },
    { name: 'twitter:card', content: 'summary_large_image' },
    {
      name: 'twitter:description',
      content:
        'Track tremor degree remotely with the online spiral drawing test',
    },
    {
      property: 'og:image',
      content: 'https://motoom.app/og-image.jpg',
    },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },
    {
      name: 'twitter:image',
      content: 'https://motoom.app/og-image.jpg',
    },
    // {
    //   name: 'msapplication-square70x70logo',
    //   content: '',
    // },
    // {
    //   name: 'msapplication-square150x150logo',
    //   content: '',
    // },
    // {
    //   name: 'msapplication-square310x310logo',
    //   content: '',
    // },
    // {
    //   name: 'msapplication-wide310x150logo',
    //   content: '',
    // },
    { property: 'og:locale', content: 'en' },
    // {
    //   property: 'og:locale:alternative',
    //   content: '',
    // },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { httpEquiv: 'X-UA-Compatible', content: 'ie=edge' },
  ];

  const canURL = `${PUBLIC_URL}${useRouter().pathname}`;

  return (
    <div className="container">
      <Head>
        <title>Motoom</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="shortcut icon" href="/icon-favicon-48.png" />
        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href="https://motoom.app/icon-apple-touch-icon512x512"
        />
        <link
          rel="apple-touch-icon"
          sizes="1024x1024"
          href="https://motoom.app/icon-apple-touch-icon1024x1024"
        />
        <link rel="canonical" href={canURL} />

        {/* Global Site Tag (gtag.js) - Google Analytics */}
        {!user && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
        )}
        {!user && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
          `,
            }}
          />
        )}

        {metaKeys.map((key, i) => (
          <meta key={i} {...key} />
        ))}
      </Head>

      <div
        className="header"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Landing user={user} />
      </div>
    </div>
  );
};

export async function getServerSideProps(ctx) {
  const user = await getInitialUser(ctx);
  return {
    props: { user: user || null, layout: LAYOUTS.HOME },
  };
}

export default Home;
