import React, { FC, useState } from 'react';
import cx from 'clsx';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { Typography } from '@material-ui/core';
import useSWR from 'swr';
import Image from 'next/image';

import { Button, Link, LoginDialog } from 'components/ui';
import { MotoomIcon } from 'components/ui/icons/MotoomIcon';

import { MAIN_COLORS } from 'utils/colors';
import { PATHS } from 'utils/paths';
import { getInitialUser } from 'utils/api';

import motoomCover from 'assets/images/motoom-cover@2x.jpg';
import howItWorks1 from 'assets/images/motoom-how-it-works-1@2x.jpg';
import howItWorks2 from 'assets/images/motoom-how-it-works-2@2x.jpg';
import howItWorks3 from 'assets/images/motoom-how-it-works-3@2x.jpg';
import howToStart1 from 'assets/images/motoom-how-to-start-1@2x.jpg';
import howToStart2 from 'assets/images/motoom-how-to-start-2@2x.jpg';
import howToStart3 from 'assets/images/motoom-how-to-start-3@2x.jpg';
import howToStart4 from 'assets/images/motoom-how-to-start-4@2x.jpg';

import { useStyles } from './styles';

export const Landing: FC<{ user: User }> = ({ user: initialUser }) => {
  const css = useStyles();

  const [login, setLogin] = useState(false);

  const { scrollY } = useViewportScroll();
  const color = useTransform(
    scrollY,
    [0, 64],
    [MAIN_COLORS.UI_LIGHT, MAIN_COLORS.UI_DARK]
  );

  const { data: user } = useSWR('/api/auth/user/', getInitialUser, {
    fallbackData: initialUser,
    revalidateOnFocus: false,
  });

  return (
    <div className={cx(css.root)}>
      <div className={css.logo}>
        <MotoomIcon variant="light" animated />
        <motion.div style={{ color }}>
          <Typography variant="h2" component="div" style={{ paddingLeft: 12 }}>
            Motoom
          </Typography>
        </motion.div>
      </div>
      <LoginDialog open={login} setOpen={setLogin} />

      <div className={cx(css.header)}>
        <div className={css.headerContent}>
          <div className={css.headerImgContainer}>
            <div className={css.headerImg}>
              <Image
                src={motoomCover}
                placeholder="blur"
                alt="Motoom"
                layout="fill"
                data-test="motoom-head-image"
              />
            </div>
          </div>

          <div className={css.headerText}>
            <Typography variant="h1">
              Track tremor degree remotely with the online spiral drawing test
            </Typography>
            <Typography variant="body2" style={{ paddingTop: 16 }}>
              Using Motoom with one observable is free of charge
            </Typography>
            <div className={css.buttons}>
              <Link href={user ? PATHS.OBSERVABLES : PATHS.SIGNUP}>
                <Button
                  variant="primaryInverse"
                  slim
                  data-test={`landing-${
                    user ? 'Your Office' : 'create-account'
                  }`}
                >
                  {user ? 'Your Office' : 'Create Account'}
                </Button>
              </Link>
              {!user && (
                <Button
                  variant="secondaryInverse"
                  slim
                  onClick={() => setLogin(true)}
                  className={css.buttonLogin}
                  data-test="landing-login"
                >
                  Log In
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={css.howItWorks}>
        <div className={css.howItWorksTitle}>
          <Typography variant="h1" component="h2">
            How it works
          </Typography>
        </div>

        <div className={cx(css.howItWorksText, css.howItWorksText1)}>
          <Typography>
            Spiral drawing is one of the standard tests used to assess tremor
            severity for the clinical evaluation of medical treatments.
            <br />
            <br />
            Motoom allows the observer to evaluate test results not only
            qualitatively, but also quantitatively. It calculates deviations
            from the reference path, acceleration changes, and other useful
            parameters. It also allows the observer to track the dynamics of
            these results.
          </Typography>
        </div>

        <div className={cx(css.howItWorksImg, css.howItWorksImg1)}>
          <Image
            src={howItWorks1}
            placeholder="blur"
            alt="Motoom How It Works"
            layout="fill"
            data-test="motoom-how-it-works-1"
          />
        </div>

        <div className={cx(css.howItWorksText, css.howItWorksText2)}>
          <Typography>
            Motoom enables remote testing, as the observer provides the test
            taker with a unique link to the web page of the test. Using the
            mouse cursor on their own laptop or desktop computer, the test taker
            draws a spiral. The test results automatically appear in Motoom for
            observer analysis.
          </Typography>
        </div>

        <div className={cx(css.howItWorksImg, css.howItWorksImg2)}>
          <Image
            src={howItWorks2}
            placeholder="blur"
            alt="Motoom How It Works"
            layout="fill"
            data-test="motoom-how-it-works-2"
          />
        </div>

        <div className={cx(css.howItWorksText, css.howItWorksText3)}>
          <Typography>
            We take into account the possible state of the test taker, so the
            test interface is extremely simple, and registration is not
            required.
            <br />
            <br />
            Motoom does not make a diagnosis. Only a certified doctor can
            evaluate the condition of the test taker.
          </Typography>
        </div>

        <div className={cx(css.howItWorksImg, css.howItWorksImg3)}>
          <Image
            src={howItWorks3}
            placeholder="blur"
            alt="Motoom How It Works"
            layout="fill"
            data-test="motoom-how-it-works-3"
          />
        </div>
      </div>

      <div className={css.border} />

      <div className={css.howToStart}>
        <div className={css.howToStartTitle}>
          <Typography variant="h1" component="h2">
            How to start
          </Typography>
        </div>

        <div className={css.howToStartBlock1}>
          <div className={css.howToStartImg}>
            <Image
              src={howToStart1}
              placeholder="blur"
              alt="Motoom How To Start"
              layout="fill"
              data-test="motoom-how-to-start-1"
            />
          </div>
          <Typography>
            To get started as an observer on Motoom, you need to create an
            account. You will be asked to enter a valid email address, which
            will grant you personal access to your data.
          </Typography>
        </div>
        <div className={css.howToStartBlock2}>
          <div className={css.howToStartImg}>
            <Image
              src={howToStart2}
              placeholder="blur"
              alt="Motoom How To Start"
              layout="fill"
              data-test="motoom-how-to-start-2"
            />
          </div>
          <Typography>
            When the account is created, you can add the profile of the first
            observable. You will find a unique link to the test page in their
            profile. Send this link exclusively to the test taker.
          </Typography>
        </div>
        <div className={css.howToStartBlock3}>
          <div className={css.howToStartImg}>
            <Image
              src={howToStart3}
              placeholder="blur"
              alt="Motoom How To Start"
              layout="fill"
              data-test="motoom-how-to-start-3"
            />
          </div>
          <Typography>
            The person being tested should open the link in their computer
            browser. The test will start automatically when the test taker
            hovers over the red circle at the beginning of the spiral, and it
            will end automatically when they move the cursor to the end of the
            reference path.
          </Typography>
        </div>
        <div className={css.howToStartBlock4}>
          <div className={css.howToStartImg}>
            <Image
              src={howToStart4}
              placeholder="blur"
              alt="Motoom How To Start"
              layout="fill"
              data-test="motoom-how-to-start-4"
            />
          </div>
          <Typography>
            When the test has been completed, you will be able to see the
            results on the observable&#39;s profile page. When more tests are
            taken with the same link, the dynamics of calculated parameters will
            be displayed.
          </Typography>
        </div>
      </div>

      <div className={css.border} />

      <div className={css.dataAndSecurity}>
        <div>
          <Typography variant="h1" component="h2">
            Data protection and security
          </Typography>

          <div style={{ paddingTop: 64 }}>
            <Typography>
              We understand the importance of protecting the privacy ​​of both
              our users and their test takers.
              <br />
              <br />
              We take all reasonable measures to protect their data. It is
              encrypted and stored in the most advanced data centers, using
              cutting-edge technology security. Backup copies of the data are
              made every 24 hours.
              <br />
              <br />
              We do not share personal data with third parties. The data is
              anonymized. We also urge our users to choose fictitious names or
              numbers for the profiles of their observables.
            </Typography>
          </div>
        </div>
      </div>

      <div className={css.tryMotoom}>
        <Typography variant="h1" component="h2">
          Try Motoom now!
        </Typography>
        <div style={{ paddingTop: 32 }}>
          <Typography>
            You can try Motoom with one observable, free of charge. Get started
            by creating your account below.
          </Typography>
        </div>
        <div style={{ paddingTop: 48 }}>
          <Link href={user ? PATHS.OBSERVABLES : PATHS.SIGNUP}>
            <Button variant="primaryInverse" slim>
              {user ? 'Your Office' : 'Create Account'}
            </Button>
          </Link>
        </div>
      </div>

      <div className={css.footer}>
        {/* <Link href="/">
          <Typography className={css.footerLink}>Privacy Policy</Typography>
        </Link> */}
        <Link href="mailto:inbox@motoom.app">
          <Typography className={css.footerLink}>Contact Us</Typography>
        </Link>
        <Typography className={css.footerCopy}>© 2021 Motoom</Typography>
      </div>
    </div>
  );
};
